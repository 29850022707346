






import { Component, Inject } from 'vue-property-decorator';
import AuthService from '@/services/auth-service';
import Vue from 'vue';

@Component
export default class Logout extends Vue {
  @Inject() AuthService!: AuthService;

  public async mounted() {
    await this.AuthService.logout();
  }
}
